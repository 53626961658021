import React, { useState, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import firebase from 'gatsby-plugin-firebase';
import Layout from '../layouts/index';
import SEO from '../components/seo';
import '../styles/Components/home.scss';
import StarWrapper from '../components/Common/StarWrapper';
import DefaultButton from '../components/Input/DefaultButton';
import RoundedInputField from '../components/Input/RoundedInputField';
import ValidStates from '../components/Input/ValidStates';
import '../styles/Components/contact.scss';
import useFormHooks from '../utils/useFormHooks';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const initialValues = {
    email: {
      value: '',
      message: '',
      regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
      state: ValidStates.NotSet,
    },
  }
  const [updateValues, checkState, removeWhiteSpace, values] = useFormHooks(initialValues);

  const isValid = values.email.state === ValidStates.Valid;
  const handleSubmit = (evt) => {
    const event = evt || window.event;
    ////
    // The following is why microsoft sucks...screw you microsoft
    ////
    if (typeof event.preventDefault === 'function') {
    event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
    event.stopPropagation();
    }
    event.returnValue = false;
    setIsLoading(true);
    firebase.auth().sendPasswordResetEmail(values.email.value)
      .then(function (user) {
        setSubmitted(true);
        setIsLoading(false);
      }).catch(function (err) {
        console.log(err)
        toast.error("Sorry! There was an error submitting the form. Please try again.", {
          position: toast.POSITION.TOP_LEFT
        });
        setIsLoading(false);
      })
    return false
  }
  return (
    <Layout>
      <SEO title="Forgot Password" />
      <div className="contact">
        <StarWrapper />
        <section className="contact-container">
          {!submitted && (
            <>
              <h2>Reset Password</h2>
              <form className="form-container" onSubmit={handleSubmit}>
                <p>EMAIL</p>
                <RoundedInputField
                    id="email"
                    type="text"
                    value={values.email.value}
                    placeholder="Email"
                    autoComplete="email"
                    checkState={checkState}
                    callback={e => removeWhiteSpace(e)}
                />
                <DefaultButton
                  text="Submit"
                  disabled={!isValid}
                  loading={isLoading}
                  callback={() => {}}
                />
              </form>
            </>
          )}
          {submitted && (
            <>
                <h2>A reset email has been sent to {values.email.value}.</h2>
                <p className="centered">Please follow the instructions in the email to continue.</p>
            </>
          )}
        </section>
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </Layout>
  )
}

export default ForgotPassword;